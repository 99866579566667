div.explanation {
  border: 2px solid #000;
  padding: 0px 10px;
}

div.explanation ol {
  padding-inline-start: 20px;
  font-weight: 450;
}

div.center {
  text-align: center;
  overflow: hidden;
}
.dropzone {
  width: 64px;
  height: 64px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 64px;
  overflow: hidden;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
}
.dropzone img {
  object-fit: cover;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
img.user-map-img {
  height: 21px;
  width: 21px;
  border-radius: 14%;
  border: #999 1px solid;
}

.user-logo {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  position: relative;
}

.dashboard-container {
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  height: calc(100vh - 68px);
}

.dashboard-map {
  width: 100%;
  overflow: hidden;
}

.left {
  position: relative;
  order: 1;
}

.right {
  position: relative;
  background: #EFF0F1;
  order: 2;
}

.no-pad-last div:last-child {
  padding-right: 15px;
}
.no-pad-right div:last-child h6 {
  margin-right: 26px;
}
@media(min-width: 960px) {

  .dashboard-responsive {
    height: calc(100vh - 68px);
    max-width: 50%!important;
  }

  .dashboard-responsive.dashboard-map {
    max-width: 100%!important;
    height: calc(100vh - 68px)!important;
  }

  .dashboard-responsive.dashboard-table {
    height: calc(100vh - 65px)!important;
    padding: 0px;
  }

  .dashboard-responsive.dashboard-table > div:first-child {
    height: calc(100vh - 65px)!important;
  }

  .dashboard-responsive.dashboard-table  > div:first-child  > div:nth-of-type(3) {
    max-height:calc(100% - 130px)!important;
  }

  .dashboard-container {
    justify-content: space-between;
  }

  .left {
      order:2;
  }

  .right {
      order:1;
  }

}

@media(max-width: 960px) {

  .dashboard-responsive.dashboard-map {
    max-width: 100%!important;
    height: calc(40vh - 68px)!important;
  }

  .dashboard-responsive.dashboard-table {
    height: calc(60vh)!important;
    padding: 0px;
    overflow: hidden;
  }

  .dashboard-responsive.dashboard-table > div:first-child {
    height: 60vh!important;
  }

  .dashboard-responsive.dashboard-table  > div:first-child  > div:nth-of-type(3) {
    max-height:calc(100% - 110px)!important;
  }
}

nav a {
  color: rgba(0, 0, 0, 87);
}

td {
  padding: 4px 6px 2px 14px!important;
}

th {
  padding: 4px 6px 2px 14px!important;
}

td div.table-img-container, td div.table-img-container img {
  max-width: 6vh;
  max-height: 6vh;
  min-height: 90%;
  border-radius: 12%;
}

div.table-img-container {
  padding: 2px;
}

.worker-status-indicator {
  height: 10px;
  width: 10px;
  bottom: 0px;
  right: -4px;
  position: absolute;
  border-radius: 6px;
}

.relative-pos-no-space {
  position: relative;
  width: 0;
  height: 0;
  float: right;
  margin-top: calc(100% + 3px);
}

.user-map-img .worker-status-indicator {
  height: 7px;
  width: 7px;
  bottom: 2px;
  right: -2px;
}

.onsite-status {
  background-color:#3de534;
}
.offsite-status {
  background-color: #ffb71c;
}
.private-status {
  background-color: #a5a5a5;
}
.offline-status {
  background-color: #3a3a3a;
}
.error-status {
  background-color: #fff;
}

.user-map-img.onsite-status,
.user-map-img.offsite-status,
.user-map-img.private-status,
.user-map-img.offline-status,
.user-map-img.error-status {
  background-color: transparent;
}

.approvals-summary-wrapper {
  display: inline-block;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
}

.approvals-summary-wrapper.righttext {
  text-align: end;
}

.approvals-summary-wrapper.elips {
  text-overflow: ellipsis;
}

.approvals-summary {
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.85em;
}

.mapboxgl-ctrl-group.disabled {
  opacity: 0;
  z-index: 0;
}

.manage-users {
  /*margin-top: 68px;*/
}

/*hack for users table height */
.manage-users-table div:nth-of-type(3n) {
  max-height: 100%!important;
}

.approvals-form-avatar {
  margin: auto;
  height: 65px;
  width: 65px;
}



